import * as Yup from "yup";

function viajeIdaValido(value) {

  if(value.viajeIda == true)
  {
    if(value.lugarIda != "" && value.lugarIda != undefined)
    {
      return true;
    }
    else
    {
      return false;
    }
  }
  return true; 
}

function viajeCuposIda(value) {
  if(value.viajeIda == true && value.viajeIda != undefined)
  {
    if(value.cuposIda  == "" || value.cuposIda== 0 || value.cuposIda == null || value.cuposIda > 5)
    {
      return false;
    }
    else
    {
      return true;
    }    
  }

  return true; 
}

function yupViajeIda(msg) {
  return function () {
    return Yup.string().test({
      name: "yupViajeIda",
      exclusive: false,
      message: `no es un rut valido`,
      test: (value) => viajeIdaValido(value),      
    });
  };
}

function yupCuposIda(msg) {
  return function () {
    return Yup.string().test({
      name: "yupCuposIda",
      exclusive: false,
      message: `no es un rut valido`,
      test: (value) => viajeCuposIda(value),      
    });
  };
}

export { viajeIdaValido, yupViajeIda,viajeCuposIda,yupCuposIda };
