import * as Yup from "yup";

function viajeVueltaValido(value) {

if(value.viajeVuelta == true)
{
  if(value.lugarVuelta != "" && value.lugarVuelta != undefined)
  {
    return true;
  }
  else
  {
    return false;
  }
  // if(value.lugarVueltaPersonalizado == true && value.lugarVueltaPersonalizado != undefined)
  // { 
  //   if(value.lugarVuelta != "" && value.lugarVuelta != undefined)
  //   {
  //     return true;
  //   }
  //   else
  //   {
  //     return false;
  //   }
  // }
  // else
  // {
  //   if(value.lugarVuelta != "" && value.lugarVuelta != undefined)
  //   {
  //     return true;
  //   }
  //   else
  //   {
  //     return false;
  //   }
  // }
}
  return true; 
}

function viajeCuposVuelta(value) {
  if(value.viajeVuelta == true && value.viajeVuelta != undefined)
  {
    if(value.cuposVuelta  == "" || value.cuposVuelta== 0 || value.cuposVuelta == null || value.cuposVuelta > 5)
    {
      return false;
    }
    else
    {
      return true;
    }    
  }

  return true; 
}


function yupViajeVuelta(msg) {
  return function () {
    return Yup.string().test({
      name: "yupViajeVuelta",
      exclusive: false,
      message: `no es un rut valido`,
      test: (value) => viajeVueltaValido(value),
      
    });
  };
}

function yupCuposVuelta(msg){
  return function () {
    return Yup.string().test({
      name: "yupCuposVuelta",
      exclusive: false,
      message: `no es un rut valido`,
      test: (value) => viajeCuposVuelta(value),      
    });
  };
}

export { viajeVueltaValido, yupViajeVuelta,yupCuposVuelta ,viajeCuposVuelta};
